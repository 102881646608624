import * as React from 'react'
import {TextField} from "@mui/material";
import Grid from "@mui/material/Grid";

export default function Profile({user}) {

    return <>
        <Grid container direction={"column"} spacing={2}>
            <Grid item>
                <TextField
                    id="email"
                    label="Email"
                    defaultValue={user?.email}
                    fullWidth
                    InputProps={{
                        readOnly: true,
                    }}
                    variant={"outlined"}
                />
            </Grid>
            <Grid item>
                <TextField
                    id="firstName"
                    label="First name"
                    defaultValue={user?.firstName}
                    fullWidth
                    InputProps={{
                        readOnly: true,
                    }}
                    variant={"outlined"}
                />
            </Grid>
            <Grid item>
                <TextField
                    id="lastName"
                    label="Last name"
                    defaultValue={user?.lastName}
                    fullWidth
                    InputProps={{
                        readOnly: true,
                    }}
                    variant={"outlined"}
                />
            </Grid>
            <Grid item>
                <TextField
                    id="tenantId"
                    label="Tenant ID"
                    defaultValue={user?.tenantId}
                    fullWidth
                    InputProps={{
                        readOnly: true,
                    }}
                    variant={"outlined"}
                />
            </Grid>
            <Grid item>
                <TextField
                    id="tenantName"
                    label="Tenant name"
                    defaultValue={user?.tenantName}
                    fullWidth
                    InputProps={{
                        readOnly: true,
                    }}
                    variant={"outlined"}
                />
            </Grid>
        </Grid>
    </>;
}