import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";

export const LeadsPanel = ({groups}) => {
    return (
        <div>
            <p>Lead groups</p>
            {groups && Object.keys(groups).map((key) => (
                <Accordion>
                    <AccordionSummary>Group {key}</AccordionSummary>
                    <AccordionDetails>
                        <div>
                            <p>Form: {groups[key].form}</p>
                            <p>Mount: {groups[key].mount}</p>
                            <p>Type: {groups[key].type}</p>
                            <p>Arrangement: {groups[key].nx}x{groups[key].ny}</p>
                            <p>Lead size: {groups[key].size}</p>
                        </div>
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
}
