import React, {useEffect, useState} from "react";
import {authenticationService} from "./auth.service";

const useAuth = () => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        authenticationService.getUser((res) => {
            setUser(res);
        }, (err) => console.log(err));
    }, []);

    return user;
};

export default useAuth;