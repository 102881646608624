import MaterialTable from "@material-table/core";
import React from "react";
import {Paper} from "@mui/material";
import {StyledEngineProvider, ThemeProvider} from "@mui/material/styles";
import {ccTableIcons, ccTableTheme} from "./ContentCreationStyles";
import {Error} from "../Error";
import AutoSizer from 'react-virtualized-auto-sizer';
import useOrders from "./useOrders";
import {CCOrdersHeader} from "./CCOrdersHeader";


export const CCPurchaseOrders = () => {
    let requests = useOrders();
    const columns =
        [
            {title: "ID", field: "purchaseOrderNumber", cellStyle: {textAlign: "left"}},
            {title: "Purchased", field: "purchasedCredits", cellStyle: {textAlign: "left"}},
            {title: "Remaining", field: "remainingCredits", cellStyle: {textAlign: "left"}},
            {title: "Creation", field: "purchaseOrderDate", type: "date", cellStyle: {textAlign: "left"}},
            {title: "Expiration", field: "expirationDate", type: "date", cellStyle: {textAlign: "left"}},
            {title: "Status", field: "status", cellStyle: {textAlign: "left"}},
        ];

    function closeErrorMessage() {
        requests.errorMessage = null;
    }

    return (
        <React.Fragment>
            <Error
                error={requests.errorMessage}
                errorMessage={requests.errorMessage}
                closeErrorMessage={closeErrorMessage}
            />
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={ccTableTheme}>
                    <AutoSizer>
                        {({height, width}) => {
                            const bodyHeight = height
                                - 400    //  Title
                                - 10    //  Footer
                            ;
                            const pageSize = Math.max(Math.floor((bodyHeight) / 38.22) - 1, 1);

                            return (
                                <div style={{height: `${height}px`, width: `${width}px`}}>
                                    <MaterialTable
                                        columns={columns}
                                        data={requests.request}
                                        icons={ccTableIcons}
                                        isLoading={requests.loading}
                                        options={{
                                            headerStyle: {
                                                backgroundColor: "#EBF0F4",
                                                fontWeight: "bolder",
                                                fontSize: 14,
                                                position: "sticky"
                                            },
                                            pageSize: pageSize,
                                            toolbar: false,
                                            maxBodyHeight: height - 240 + "px",
                                            defaultExpanded: false,
                                            filtering: false,
                                            exportAllData: true,
                                            showTitle: true,
                                            rowStyle: () => {
                                                return {wordWrap: "break-word", borderBottom: "none", fontSize: 14};
                                            },
                                            padding: "normal",
                                            paginationPosition: "top",
                                            tableLayout: "fixed",
                                            columnResizable: true,
                                            draggable: true
                                        }}
                                        components={{
                                            Pagination: (props) => (
                                                <CCOrdersHeader rows={requests?.request?.length} {...props}/>
                                            ),
                                            Container: (props) => <Paper {...props} elevation={0}/>
                                        }}
                                    />
                                </div>
                            );
                        }}
                    </AutoSizer>
                </ThemeProvider>
            </StyledEngineProvider>
        </React.Fragment>
    );
}