import React, {useState} from "react";
import {DialogContent, DialogContentText, IconButton, Menu, TextField} from "@mui/material";
import {MdAddLink, MdLink} from "react-icons/md";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {VPLTooltip} from "../VPLTooltip";

export const PartDatasheetLink = ({part, notify}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [link, setLink] = useState(part != null ? part.datasheetURL : null);
    const open = Boolean(anchorEl);
    const anchorOrig = {
        vertical: "top",
        horizontal: "left"
    };

    function handleClose() {
        setAnchorEl(null);
        notify(part)
    }

    function handleDatasheetChange(event) {
        setLink(event.target.value);
        part.datasheetURL = event.target.value;
    }

    return (
        <React.Fragment>
            <VPLTooltip
                placement="bottom-start"
                title="Add link to part's datasheet"
            >
                <IconButton
                    style={{padding: 0, margin: 5}}
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget)
                    }}
                    size="large">
                    {link
                        ? <MdLink style={{color: "lightblue"}}/>
                        : <MdAddLink style={{color: "lightblue"}}/>
                    }
                </IconButton>
            </VPLTooltip>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={anchorOrig}
                transformOrigin={anchorOrig}
                PaperProps={{
                    style: {
                        width: 800
                    }
                }}
            >
                <DialogContent>
                    <DialogContentText>
                        <Grid container direction={"row"}>
                            <Grid item xs={11} md={11} lg={11}>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label={<Typography variant="body1">&#8288;Datasheet link</Typography>}
                                    variant={"outlined"}
                                    fullWidth
                                    value={link}
                                    onChange={handleDatasheetChange}
                                />
                            </Grid>
                            <Grid item xs={1} md={1} lg={1}>
                                <IconButton
                                    aria-label='Open link'
                                    onClick={() => {
                                        window.open(link, "_blank")
                                    }}
                                    size="large">
                                    <MdLink style={{color: "lightblue"}}/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
            </Menu>
        </React.Fragment>
    );
}