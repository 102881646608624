import {Button, DialogActions} from "@mui/material";
import React from "react";

const style = {backgroundColor: 'white', borderColor: '#016684', borderRadius: 0,  textTransform: "none", fontSize: 14, color: '#016684' };

export const VPLDialogActions = ({handleClose, handleSubmit}) => {

    return (
        <DialogActions>
            <Button variant="outlined" onClick={handleClose} style={style}>
                Cancel
            </Button>
            <Button autoFocus onClick={handleSubmit} variant="outlined" style={style}>
                OK
            </Button>
        </DialogActions>
    );
}