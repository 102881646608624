import {authenticationService} from "./auth.service";

export class ContentCreationClient {

    getRequest = async (ccId) => {
        const url = `${authenticationService.getCCServiceUrl()}/tenants/${authenticationService.getTenantId()}/cc/${ccId}`;
        const res = await fetch(url, {
            method: "GET",
            headers: this.createHeaders()
        }).catch((e) => {
            console.log(e);
            return {errorMessage: "Failed to get request", request: null};
        });

        if (res.ok) {
            const request = await res.json();
            return {errorMessage: null, request};
        } else {
            return {errorMessage: "Failed to get request: " + res.statusText, request: null};
        }
    }

    getPurchaseOrders = async () => {
        const url = `${authenticationService.getCCServiceUrl()}/tenants/${authenticationService.getTenantId()}/cc/orders`;
        const res = await fetch(url, {
            method: "GET",
            headers: this.createHeaders()
        }).catch((e) => {
            console.log(e);
            return {errorMessage: "Failed to get purchase orders", request: null};
        });

        if (res.ok) {
            const request = await res.json();
            return {errorMessage: null, request};
        } else {
            return {errorMessage: "Failed to get request: " + res.statusText, request: null};
        }
    }

    getMessages = async () => {
        const url = `${authenticationService.getCCServiceUrl()}/tenants/${authenticationService.getTenantId()}/cc/messages`;
        const res = await fetch(url, {
            method: "GET",
            headers: this.createHeaders()
        }).catch((e) => {
            console.log(e);
            return {errorMessage: "Failed to get messages", request: []};
        });

        if (res.ok) {
            const request = await res.json();
            return {errorMessage: null, request};
        } else {
            return {errorMessage: "Failed to get request: " + res.statusText, request: []};
        }
    }

    getCredits = async () => {
        const url = `${authenticationService.getCCServiceUrl()}/tenants/${authenticationService.getTenantId()}/cc/credits`;
        const res = await fetch(url, {
            method: "GET",
            headers: this.createHeaders()
        }).catch((e) => {
            console.log("Failed to get credits amount");
            console.log(e);
            return 0;
        });
        if (res.ok) {
            return res.json();
        } else {
            return 0;
        }
    }

    updateRequest = (request, resolve, reject) => {
        const url = `${authenticationService.getCCServiceUrl()}/tenants/${authenticationService.getTenantId()}/cc/${request.id}`;
        console.log(JSON.stringify(request));
        fetch(url, {
            method: "PUT",
            headers: this.createHeaders(),
            body: JSON.stringify(request)
        })
        .then(res => {
            if (res.status === 200) {
                resolve();
            } else if (res.status === 409) {
                console.log("Not enough credits");
                reject("There are not enough credits to complete this request.");
            } else {
                console.log(res);
                reject("Failed to submit request");
            }
        })
        .catch((e) => {
            console.log(e);
            reject("Failed to submit request");
        });
    }

    getRequests = (resolve, reject) => {
        const url = `${authenticationService.getCCServiceUrl()}/tenants/${authenticationService.getTenantId()}/cc`;
        fetch(url, {
            method: "GET",
            headers: this.createHeaders()
        })
            .then(res => {
                return res.json();
            })
            .then(res => resolve(res))
            .catch((e) => {
                console.log(e);
                reject(e);
            });
    }

    createCustomHeaders = (contentType) => {
        const headers = new Headers();
        headers.append("Content-Type", contentType);
        if (authenticationService.isDev()) {
            headers.append("Authorization", "Bearer " + authenticationService.getToken());
        }
        return headers;
    }

    createHeaders() {
       return this.createCustomHeaders("application/json");
    }

    getVplComponent = (mpn, manufacturer, resolve, reject) => {
        const url = `${authenticationService.getPartsServiceUrl()}/tenants/${authenticationService.getTenantId()}/svg/${manufacturer}/${mpn}`;
        const headers = this.createHeaders();
        headers.append("width", "600");
        headers.append("height", "600");
        fetch(url, {
            method: "GET",
            headers
        })
        .then(res => res.text())
        .then(res => resolve(res))
        .catch((e) => {
            console.log(e);
            reject(e);
        });
    };

    addRequest = (file, resolve, reject) => {
        const url = `${authenticationService.getCCServiceUrl()}/tenants/${authenticationService.getTenantId()}/cc`;
        const headers = this.createCustomHeaders("application/octet-stream");
        const filename = file.name.replace(/[^a-z0-9_.-]/gi, '_');
        headers.append("filename", filename);
        fetch(
            url,
            {
                headers,
                method: 'POST',
                body: file,
            }
        )
        .then((response) => response.json())
        .then((result) => {
            console.log('Success:', result);
            resolve(result);
        })
        .catch((error) => {
            console.error('Error:', error);
            reject(error);
        });
    }

    deleteRequest = (ccId, resolve, reject) => {
        const url = `${authenticationService.getCCServiceUrl()}/tenants/${authenticationService.getTenantId()}/cc/${ccId}`;
        fetch(url,
            {
                method: 'DELETE',
                headers: this.createHeaders(),
            })
            .then(result => {
                if (result.status === 200) {
                    resolve();
                } else if (result.status === 404) {
                    console.log("Not Entity or Parts");
                    reject("There is no entity or parts.");
                } else {
                    console.log(result);
                    reject("Failed to delete request");
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                reject(error);
            });
    }

    addRequestAttachment = (ccId, file, resolve, reject) => {
        const url = `${authenticationService.getCCServiceUrl()}/tenants/${authenticationService.getTenantId()}/cc/${ccId}/attachments`;
        const headers = this.createCustomHeaders("application/octet-stream");
        const filename = file.name.replace(/[^a-z0-9_.-]/gi, '_');
        headers.append("filename", filename);
        fetch(
            url,
            {
                headers,
                method: 'POST',
                body: file,
            }
        )
        .then((response) => {
            if (response.status === 200) {
                resolve("OK");
            } else {
                reject("Failed to upload file");
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            reject(error);
        });
    };

    addPartAttachment = (ccId, partId, file, resolve, reject) => {
        const url = `${authenticationService.getCCServiceUrl()}/tenants/${authenticationService.getTenantId()}/cc/${ccId}/${partId}/attachments`;
        const headers = this.createCustomHeaders("application/octet-stream");
        const filename = file.name.replace(/[^a-z0-9_.-]/gi, '_');
        headers.append("filename", filename);
        fetch(
            url,
            {
                headers,
                method: 'POST',
                body: file,
            }
        )
        .then((response) => {
            if (response.status === 200) {
                resolve("OK");
            } else {
                console.error('Failed to upload part attachment');
                console.error(response);
                reject("Failed to upload part attachment");
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            reject(error);
        });
    };

    downloadRequestAttachment = (ccId, filename) => {
        const url = `${authenticationService.getCCServiceUrl()}/tenants/${authenticationService.getTenantId()}/cc/${ccId}/attachments`;
        fetch(url, {
            method: "GET",
            headers: this.createHeaders()
        })
        .then(response => {
            this.handleDownloadedFile(response, filename);
        })
        .catch((e) => {
            console.log(e);
        });
    }

    downloadImportFile = (ccId, filename) => {
        const url = `${authenticationService.getCCServiceUrl()}/tenants/${authenticationService.getTenantId()}/cc/${ccId}/import`;
        fetch(url, {
            method: "GET",
            headers: this.createHeaders()
        })
        .then(response => {
            this.handleDownloadedFile(response, filename);
        })
        .catch((e) => {
            console.log(e);
        });
    }

    downloadReport = (ccId, filename) => {
        const url = `${authenticationService.getCCServiceUrl()}/tenants/${authenticationService.getTenantId()}/cc/${ccId}/report`;
        fetch(url, {
            method: "GET",
            headers: this.createHeaders()
        })
        .then(response => {
            this.handleDownloadedFile(response, filename);
        })
        .catch((e) => {
            console.log(e);
        });
    }

    handleDownloadedFile(response, filename) {
        if (response.status && response.status === 200) {
            // Create blob link to download
            response
                .blob({type: "application/octet-stream", responseType: "arraybuffer"})
                .then((blob) => {
                    const downloadUrl = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = downloadUrl;
                    a.download = filename;
                    a.click();
                });
        } else {
            console.log(response);
            console.error(error);
        }
    }

    downloadPartAttachment = (ccId, partId, filename) => {
        const url = `${authenticationService.getCCServiceUrl()}/tenants/${authenticationService.getTenantId()}/cc/${ccId}/${partId}/attachments`;
        fetch(url, {
            method: "GET",
            headers: this.createHeaders()
        })
        .then(response => {
            this.handleDownloadedFile(response, filename);
        })
        .catch((e) => {
            console.log(e);
        });
    };

}
