import MaterialTable from "@material-table/core";
import React, {useState} from "react";
import {StyledEngineProvider, ThemeProvider} from "@mui/material/styles";
import {Box, Paper, Tooltip} from "@mui/material";
import {ContentCreationTableHeader} from "./ContentCreationTableHeader";
import {
    ccHeaderStyle,
    ccTableIcons,
    ccTableTheme,
    ColorButton,
    submitButtonStyle,
    WhiteTextTypography,
} from "./ContentCreationStyles";
import Grid from "@mui/material/Grid";
import {PartButtons} from "./PartButtons";
import ComponentViewer from "../draw/ComponentViewer";
import {Error} from "../Error";
import {ContentCreationClient} from "../../services/ContentCreationClient";
import {ProgressBar} from "../ProgressBar";
import useRequest from "./useRequest";

const manufacturerColumn = {title: "Manufacturer", field: "manufacturerName"};
const mpnColumn = {
    title: "MPN",
    field: "mpn",
    render: (row) => (
        <Tooltip placement="bottom-start" title={row.mpn}>
            <span>{row.mpn}</span>
        </Tooltip>
    )
};

function getVPLPartStatus(part) {
    if (part.partStatus === "not_found")
        return "Not Found";
    if (part.partStatus === "found")
        return "Found";
    if (part.partStatus === "invalid_part_number")
        return "Invalid";
    return part.partStatus;
}

function getCCJobPartStatus(part) {
    if (part.isExist) {
        return "Exist";
    }
    return part.partStatus;
}

const selectedColumn = {
    title: "Selected",
    field: "isUse",
    type: "boolean",
    defaultSort: "desc",
    hidden: true
};

export const CCTable = () => {
    const MAX_ROWS_PER_PAGE = 100;
    const [updating, setUpdating] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const request = useRequest();

    let currentRows = request?.request?.mpnMcodeNames?.length | 0;
    currentRows = currentRows > MAX_ROWS_PER_PAGE ? MAX_ROWS_PER_PAGE : currentRows;

    const updateAttachment = (newAttachment) => {
        if (request?.request) {
            request.request.attachment = newAttachment;
        }
    }

    const updateService = (newService) => {
        if (request?.request) {
            request.request.ccServiceType = newService;
        }
    }

    const updateComments = (newComments) => {
        if (request?.request) {
            request.request.comments = newComments;
        }
    }

    const notifyPartChange = (part) => {
        if (part && request?.request) {
            for (let currentPart of request.request?.mpnMcodeNames) {
                if (currentPart.id === part.id) {
                    currentPart.note = part.note;
                    currentPart.datasheetURL = part.datasheetURL;
                    break;
                }
            }
        }
    };

    function createRequest() {
        if (request?.request) {
            return {
                id: request.request.id, name: request.request.name, ccServiceType: request.request.ccServiceType,
                comments: request.request.comments, mpnMcodeNames: request.request.mpnMcodeNames
            };
        }
    }

    function submit() {
        if (!isAtLeastOnePartisUse()) {
            setErrorMessage("No Part Selected");
        } else {
            setUpdating(true);
            const ccClient = new ContentCreationClient();
            ccClient.updateRequest(createRequest(), () => {
                window.location.reload(true)
            }, (newError) => {
                setUpdating(false);
                console.log(newError);
                setErrorMessage(newError);
            })
        }
    }

    function closeErrorMessage() {
        setErrorMessage(null);
    }

    const getTableComponents = () => {
        return {
            Pagination: (props) => (
                <ContentCreationTableHeader
                    tableProps={props}
                    request={request?.request}
                    updateComments={updateComments}
                    updateService={updateService}
                    updateAttachment={updateAttachment}
                />
            ),
            Container: (props) => <Paper {...props} elevation={0}/>,
        };
    }

    function getInitiatedRequestColumns() {
        return [
            selectedColumn,
            manufacturerColumn,
            mpnColumn,
            {
                field: "partStatus",
                title: "Status",
                render: (row) => getVPLPartStatus(row)
            },
            {
                field: "info",
                title: "Info",
                render: (row) => <PartButtons requestId={request?.request?.id} part={row} notify={notifyPartChange}/>
            }
        ];
    }

    function getSubmittedRequestColumns() {
        return [
            manufacturerColumn,
            mpnColumn,
            {
                field: "partStatus",
                title: "Status",
                render: (row) => getCCJobPartStatus(row)
            },
            {
                field: "info",
                title: "Info",
                render: (row) => <PartButtons requestId={request?.request?.id} part={row} notify={notifyPartChange}/>
            },
        ];
    }

    function getColumns() {
        if (request?.request?.status === 'initiated') {
            return getInitiatedRequestColumns();
        } else {
            return getSubmittedRequestColumns();
        }
    }

    function isAtLeastOnePartisUse() {
        for (let part of request?.request?.mpnMcodeNames) {
            if (part.isUse) {
                return true;
            }
        }
        return false
    }

    const detailPanel = [
        part => ({
            disabled: !part.isExist && part.partStatus !== 'created',
            render: () => <ComponentViewer mpn={part.mpn} mnf={part.manufacturerName}/>
        })
    ];
    let initiatedRequest = request?.request?.status === 'initiated';

    function handleClick(rows) {
        for (let part of request?.request?.mpnMcodeNames) {
            part.isUse = false;
        }
        for (let currentPart of rows) {
            for (let part of request?.request?.mpnMcodeNames) {
                if (part.id === currentPart.id) {
                    part.isUse = currentPart.tableData.checked;
                    break;
                }
            }
        }
    }

    return (
        <React.Fragment>
            <Error
                error={errorMessage || request.errorMessage}
                errorMessage={errorMessage ? errorMessage : request.errorMessage}
                closeErrorMessage={closeErrorMessage}
            />
            {updating && <ProgressBar/>}
            {!updating && <StyledEngineProvider injectFirst>
                <ThemeProvider theme={ccTableTheme}>
                    <Grid container direction={"column"} spacing={0}>
                        <Grid item>
                            <MaterialTable
                                title={"Parts"}
                                icons={ccTableIcons}
                                options={
                                    {
                                        headerStyle: ccHeaderStyle,
                                        filtering: false,
                                        pageSize: currentRows,
                                        pageSizeOptions: [],
                                        paginationPosition: "top",
                                        toolbar: true,
                                        paging: true,
                                        search: true,
                                        rowStyle: () => {
                                            return {
                                                wordWrap: "break-word",
                                                borderBottom: "none",
                                                fontSize: 14,
                                                height: 30
                                            };
                                        },
                                        actionsColumnIndex: -1,
                                        draggable: true,
                                        selection: initiatedRequest,
                                        showTextRowsSelected: initiatedRequest,
                                        sorting: true
                                    }
                                }
                                onSelectionChange={rows => {
                                    handleClick(rows);
                                }}
                                components={getTableComponents()}
                                detailPanel={detailPanel}
                                isLoading={request?.loading}
                                columns={getColumns()}
                                data={request?.request?.mpnMcodeNames}
                                localization={{
                                    toolbar: {
                                        nRowsSelected: '{0} part(s) selected for creation'
                                    },
                                    header: {
                                        actions: 'Actions'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'No parts to display',
                                        filterRow: {
                                            filterTooltip: 'Filter'
                                        }
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item>
                            <Box display="flex" flexDirection="row" justifyContent="flex-end">
                                <ColorButton
                                    variant="outlined"
                                    onClick={submit}
                                    style={submitButtonStyle}>
                                    <WhiteTextTypography
                                        variant="caption">{initiatedRequest ? 'Submit' : 'Update'}</WhiteTextTypography>
                                </ColorButton>
                            </Box>
                        </Grid>

                    </Grid>
                </ThemeProvider>
            </StyledEngineProvider>}
        </React.Fragment>
    );


}

