import React from 'react';
import logo from '../logo.svg';
import styled from 'styled-components';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const HeaderBar = styled.header`
    width: 100%;
    padding: 0.5em 1em;
    display: flex;
    height: 50px;
    position: fixed;
    align-items: center;
    background-color: #016684;
    foreground-color: #white;    
`;

const Header = () => {
return (
    <HeaderBar>
        <Grid container direction={"row"} justifyContent={"flex-start"} alignItems={"center"} alignContent={"center"} spacing={2}>
            <Grid item>
                <img src={logo} alt="Logo" height="40" />
            </Grid>
            <Grid item>
                <Typography style={{color: 'white', fontSize: 20, fontcolor: 'white', fontWeight: 'bold'}}> Valor Parts Library</Typography>
            </Grid>
        </Grid>
    </HeaderBar>
    );
};
export default Header;