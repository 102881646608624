import {Box, DialogTitle, IconButton} from "@mui/material";
import {MdClose} from "react-icons/md";
import React from "react";

export const VPLDialogTitle = ({title, handleClose}) => {

    return (
        <DialogTitle
            id="draggable-dialog-title"
            style={{
                backgroundColor: "#006486",
                color: "white",
                marginBottom: 0,
                marginTop: 0,
                paddingBottom: 0,
                paddingTop: 0,
                cursor: 'move'
            }}
        >
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                    <span style={{color: "white", fontSize: 14}}>{title}</span>
                </Box>
                <Box>
                    <IconButton onClick={handleClose} size="large">
                        <MdClose style={{color: "white"}}/>
                    </IconButton>
                </Box>
            </Box>
        </DialogTitle>
    );
}