import React from "react";
import {ColorButton, creditButtonStyle, WhiteTextTypography} from "./ContentCreationStyles";
import useCredits from "./useCredits";
import {useNavigate} from "react-router-dom";

const Credits = () => {
    const credits = useCredits();
    const navigate = useNavigate();

    function onClick() {
        navigate(`/cc/orders`);
    }

    return (
        <ColorButton variant="outlined" style={creditButtonStyle} onClick={onClick}>
            <div>
                <WhiteTextTypography variant="h5">{credits}</WhiteTextTypography>
                <WhiteTextTypography variant="caption">Credit left</WhiteTextTypography>
            </div>
        </ColorButton>
    );

};
export default Credits;

