import React, {useState} from "react";
import {StyledEngineProvider, ThemeProvider} from "@mui/material/styles";
import {ccHeaderTheme} from "./ContentCreationStyles";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {RequestAttachments} from "./RequestAttachments";
import {TablePagination} from "@mui/material";
import Credits from "./Credits";
import {DeleteRequest} from "./DeleteRequest";

const costOptimizationTypes = [
    {value: "days3", label: "3 days"},
    {value: "days5", label: "5 days"},
    {value: "days15", label: "15 days"}
];

export const ContentCreationTableHeader = ({tableProps, request, updateAttachment, updateService, updateComments}) => {
    const [comments, setComments] = useState(request?.comments);
    const [service, setService] = useState(request?.ccServiceType);

    const canBeDeleted = request?.status === 'completed' || request?.status === 'initiated';

    function selectType(e) {
        let newService = e.target.value;
        if (newService !== service) {
            setService(newService);
            updateService(newService);
        }
    }

    function getTitle() {
        if (request?.id?.length > 0) {
            return "Request " + request.id + " - " + request.name;
        } else {
            return "";
        }
    }

    function internalSetComments(e) {
        let newComments = e.target.value;
        if (comments !== newComments) {
            setComments(newComments);
            updateComments(newComments);
        }
    }

    return (
        <div style={{boxShadow: "none", border: "1px solid #006486", padding: 10}}>
            <span style={{fontWeight: 'normal', fontSize: 24, padding: 10, margin: 10}}>{getTitle()}</span>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={ccHeaderTheme}>
                    <Grid
                        container
                        direction="row"
                        spacing={1}
                        justifyContent="space-between"
                        alignItems="stretch"
                        alignContent="stretch"
                        style={{marginTop: 10}}
                    >
                        <Grid item xs={1}>
                            <TextField
                                id="status"
                                label="Status"
                                value={request?.status || ""}
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <TextField
                                key="service"
                                id="serviceType"
                                label="Service type"
                                select
                                value={service || ""}
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                SelectProps={{native: true}}
                                onChange={selectType}
                            >
                                {costOptimizationTypes.map(option => (
                                    <option key={option.value} value={option.value}>
                                        {" "}
                                        {option.label}
                                    </option>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                key="comments"
                                autoFocus
                                id="comments"
                                label="Comments"
                                type="string"
                                value={comments || ""}
                                fullWidth
                                multiline
                                rows={3}
                                InputLabelProps={{shrink: true}}
                                inputProps={{maxLength: 512}}
                                variant="outlined"
                                margin="dense"
                                onChange={internalSetComments}
                            />
                        </Grid>
                        <Grid item cs={1}>
                            <RequestAttachments ccId={request?.id} setAttachment={updateAttachment}
                                                status={request?.status} request={request}/>
                        </Grid>
                        {canBeDeleted && <Grid item cs={1}>
                            <DeleteRequest ccId={request?.id} request={request}/>
                        </Grid>}

                        <Grid item xs={1}>
                            <Credits/>
                        </Grid>
                        <Grid item xs={3}>
                            <TablePagination {...tableProps}/>
                        </Grid>
                    </Grid>
                </ThemeProvider>
            </StyledEngineProvider>

        </div>
    );


}
