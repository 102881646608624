import React, {useState} from "react";
import {DialogContent, DialogContentText, IconButton, Menu, TextField} from "@mui/material";
import {MdAddComment, MdComment} from "react-icons/md";
import Typography from "@mui/material/Typography";
import {VPLTooltip} from "../VPLTooltip";

export const PartNotes = ({part, notify}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [note, setNote] = useState(part != null ? part.note:null);
    const open = Boolean(anchorEl);
    const anchorOrig = {
        vertical: "top",
        horizontal: "left"
    };

    function handleClose() {
        setAnchorEl(null);
        notify(part);
    }

    function handleChange(event) {
        setNote(event.target.value);
        part.note = event.target.value;
    }

    return (
        <React.Fragment>
            <VPLTooltip
                placement="bottom-start"
                title="Add comments"
            >
                <IconButton
                    data-
                    style={{padding:0, margin:5}}
                    onClick={(event) => {setAnchorEl(event.currentTarget)}}
                    size="large">
                    {note
                        ? <MdComment style={{color: "lightblue"}}/>
                        : <MdAddComment style={{color: "lightblue"}}/>
                    }
                </IconButton>
            </VPLTooltip>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={anchorOrig}
                transformOrigin={anchorOrig}
                PaperProps={{
                    style: {
                        width: 800
                    }
                }}
            >
                <DialogContent>
                    <DialogContentText>
                        <TextField
                            id="outlined-multiline-flexible"
                            label={<Typography variant="body1">&#8288;Notes</Typography>}
                            variant={"outlined"}
                            multiline
                            fullWidth
                            rows={4}
                            value={note}
                            onChange={handleChange}
                        />
                    </DialogContentText>
                </DialogContent>
            </Menu>
        </React.Fragment>
    );

}