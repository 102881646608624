import React, {useEffect, useState} from "react";
import {ContentCreationClient} from "../../services/ContentCreationClient";

const useCredits = () => {
    const [credits, setCredits] = useState(0);

    useEffect(() => {
        const ccClient = new ContentCreationClient();
        ccClient.getCredits().then(res => setCredits(res));
    }, []);

    return credits;
};

export default useCredits;