import {CircularProgress} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useState} from "react";

export const IFramePanel = ({url}) => {
    const [loading, setLoading] = useState(true);

    function hideSpinner() {
        setLoading(false);
    }

    return (
        <div style={{width: '100%', height: '100%'}}>
            {loading && (
                <div>
                    <CircularProgress
                        size="2%"
                        style={{
                            position: "absolute",
                            marginLeft: "20%",
                            marginTop: "20%",
                            color: "#016684"
                        }}
                    />
                    <Typography
                        style={{
                            fontSize: 10,
                            position: "absolute",
                            marginLeft: "20%",
                            marginTop: "25%",
                            color: "#016684"
                        }}
                    >
                        Finding parts...
                    </Typography>
                </div>
            )}
            <iframe
                src={url}
                width="100%"
                height="100%"
                color="white"
                style={{ opacity: loading ? 0 : 1, border: "none" }}
                allowTransparency
                onLoad={hideSpinner}
            />
        </div>
    );
}
