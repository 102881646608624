import React, {useEffect, useState} from "react";
import {ContentCreationClient} from "../../services/ContentCreationClient";
import {useParams} from "react-router-dom";

const useRequest = () => {
    const {ccId} = useParams();
    const [request, setRequest] = useState({request: null, errorMessage: null, loading: true});

    useEffect(() => {
        let abortController = new AbortController();
        const ccClient = new ContentCreationClient();
        ccClient.getRequest(ccId).then(res => {
            console.log(res)
            for(let part of res?.request?.mpnMcodeNames) {
                part.tableData = {checked: part.isUse};
            }
            res.loading = false;
            setRequest(res)
        });
        return () => {
            abortController.abort();
        }
    }, [ccId])

    return request;
};

export default useRequest;