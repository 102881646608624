import Tooltip from "@mui/material/Tooltip";
import {styled} from "@mui/material/styles";

export const VPLTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .MuiTooltip-tooltip`]: {
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: 11
    }
}));

