import React from "react";
import background from "./Login_Valor_VPL_Content_Creation_Services.jpg";
import {Button, Typography} from "@mui/material";
import {authenticationService} from "../services/auth.service";
import {ContentCreationRequests} from "../components/cc/CCRequests";

const sectionStyle = {
    position: 'absolute', left: '50%', top: '50%',
    transform: 'translate(-50%, -50%)',
    width: "1200px",
    height: "760px",
    backgroundImage: "url(" + background + ")",
    justifyContent: "center",
    alignItems: "center"
};
const divStyle = {
    display: "block",
    backgroundColor: "white",
    position: 'absolute', left: '50%', top: '50%',
    transform: 'translate(-50%, -50%)',
    width: "100%",
    height: "100%",
};
const copyrightsStyle = {
    textTransform: 'none',
    color: 'white',
    position: 'absolute', left: '4.5%', top: '79%',
    fontSize: 12
};
const disclaimerStyle = {
    textTransform: 'none',
    color: 'white',
    position: 'relative', left: '4.5%', top: '82%',
    fontSize: 11
};
const buttonStyle = {
    backgroundColor: '#EB780A',
    textTransform: 'none',
    fontSize: 16,
    position: 'absolute',
    left: '50%',
    top: '61%',
    transform: 'translate(-50%, -50%)',
    width: 140,
    height: 60
};


export function Login() {
    if (authenticationService.isAuthenticated()) {
        return <ContentCreationRequests/>;
    }

    return (
        <div style={divStyle}>
            <section style={sectionStyle}>
                <Button id="signin" variant={"outlined"}
                        onClick={() => window.location = "/login"}
                        style={buttonStyle}>
                    <Typography style={{color: 'white'}}>Sign In</Typography>
                </Button>
                <Typography style={copyrightsStyle}>Unpublished work. ©2023 Siemens.</Typography>
                <Typography style={disclaimerStyle}>This material contains trade secrets or otherwise confidential
                    information owned by Siemens</Typography>
                <Typography style={disclaimerStyle}>Industry Software Inc. or its affiliates (collectively, "Siemens"),
                    or its licensors.Access to and use of this</Typography>
                <Typography style={disclaimerStyle}>information is strictly limited as set forth in the Customer's
                    applicable agreements with Siemens.</Typography>
                <Typography style={disclaimerStyle}>
						<span>
							A list of relevant Siemens trademarks can be found
							<a style={{color: 'white'}}
                               href="https://www.plm.automation.siemens.com/global/en/legal/trademarks.html"> here</a>. Other trademarks belong to their respective owners.
					</span>
                </Typography>
            </section>
        </div>
    );
}
