import React, {useEffect} from 'react';
import {authenticationService} from '../services/auth.service'
import {ProgressBar} from "./ProgressBar";

export default function ILogout() {
    useEffect(() => {
        if (authenticationService.isAuthenticated()) {
            authenticationService.logout(
                (url) => window.location.replace(url),
                () => window.location.replace("/")
            );
        } else {
            window.location.replace("/");
        }
    }, [])

    return <ProgressBar/>;
}