
export const authenticationService = {
    getToken,
    getTenantId,
    getUserId,
    logout,
    isAuthenticated,
    getCCServiceUrl,
    getPartsServiceUrl,
    isDev,
    getUser
};

    const dev = false;

    function logout(resolve, reject) {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        const requestOptions = {
            method: 'GET',
            headers,
        };
        const url = `${getFrontendUrl()}/signout`;
        fetch(url, requestOptions)
        .then(user => {
            return user.text();
        }).then(res => {
            localStorage.clear();
            resolve(res)
        }).catch((e) => {
            reject(e);
        });
    }

    function getToken() {
        return isDev() ? getCookie("valor-auth") : null;
    }

    function getTenantId() {
        return getCookie("valor-tenantId");
    }

    function getUserId() {
        return getCookie("valor-userId");
    }

    function getCCServiceUrl() {
        return dev ? "https://dev.vpl.sws.siemens.com/vpl/api/v1/bom" : "/vpl/api/v1/bom";
    }

    function getPartsServiceUrl() {
        return dev ? "https://dev.vpl.sws.siemens.com/vpl/api/v1/parts" : "/vpl/api/v1/parts";
    }

    function getFrontendUrl() {
        return "";
    }

    function isDev() {
    return dev;
    }

    function isAuthenticated() {
        const cookie = getCookie('valor-expires');
        let now = new Date().getTime() / 1000;
        console.log("now: " + now)
        if (cookie) {
            const expiry = parseInt(cookie);
            console.log("expires: " + expiry)
            if (expiry < now) {
                console.log("EXPIRED");
                return false;
            } else {
                const user  = localStorage.getItem("user");
                if (!user) {
                    getUser((res)=>{localStorage.setItem("user", JSON.stringify(res))}, (err)=>console.log(err));
                }
                return true;
            }
        }
        return false;
    }

    function getUser(resolve, reject) {
        let user = localStorage.getItem("user");
        if (user && user !== 'undefined') {
            console.log("user exists")
            console.log(user)
            resolve(JSON.parse(user));
        } else {
            const url = `/user`;
            const headers = new Headers();
            headers.append("Content-Type", "application/json");
            fetch(url, {
                method: "GET",
                headers
            })
            .then(res => {
                if(res.status === 200) {
                    console.log("got user from server")
                    return res.json();
                } else {
                    reject(res);
                }
            })
            .then(res => {
                localStorage.setItem("user", JSON.stringify(res));
                console.log("user ")
                console.log(res);
                resolve(res);
            })
            .catch((e) => {
                console.log(e);
                reject(e);
            });
        }
    }

    function getCookie(name) {
        return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';
    }