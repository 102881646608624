import './Alert.css';
import {useState} from "react";

export const Alert = ({message}) => {
    const [isShow, setIsShow] = useState(true);

    const handleClose = (e) => {
        e.preventDefault();
        setIsShow(false);
    };

    return (
        <div data-testid="alertDiv" id="alertDiv" className={"alert warning" + (isShow ? "" : " hide")} role="alert">
            <strong id="message">{message}</strong>
            <span className="closebtn" onClick={handleClose}>
               &times;
            </span>
        </div>
    );
}
