import React, {useEffect, useState} from "react";
import {ContentCreationClient} from "../../services/ContentCreationClient";

const useRequests = () => {
    const [requests, setRequests] = useState({requests: [], errorMessage: null, loading: true});

    useEffect(() => {
        let abortController = new AbortController();
        const ccClient = new ContentCreationClient();
        ccClient.getRequests(
            (receivedData) => {
                setRequests({requests: receivedData, errorMessage: null, loading: false});
            },
            (receivedError) => {
                setRequests({requests: null, errorMessage: receivedError, loading: false});
            }
        );
        return () => {
            abortController.abort();
        }
    }, []);

    return requests;
};

export default useRequests;