import {
    MdAddBox,
    MdArrowUpward,
    MdCheck,
    MdChevronLeft,
    MdChevronRight,
    MdClear,
    MdDeleteOutline,
    MdEdit,
    MdFirstPage,
    MdLastPage,
    MdOutlineAttachFile,
    MdRemove,
    MdSaveAlt,
    MdSearch,
    MdSwapHoriz,
    MdViewColumn
} from "react-icons/md";
import React, {forwardRef} from "react";
import {Button} from "@mui/material";
import {createTheme, styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";

export const ColorButton = styled(Button)(() => ({
    color: "#E0AD5D",
    backgroundColor: "white"
}));

export const WhiteTextTypography = styled(Typography)(() => ({
    color: "#FFFFFF"
}));

export const ccHeaderTheme = createTheme({
    palette: {
        primary: {
            main: "#016684"
        },
        secondary: {
            main: "#016684"
        }
    }
});

export const ccTableTheme = createTheme({
    palette: {
        primary: {
            main: "#016684"
        },
        secondary: {
            main: "#016684"
        }
    },
    components: {
        MuiTableRow: {
            styleOverrides: {
                root: {
                    "&:nth-of-type(odd)": {
                        backgroundColor: "#F8F8F8"
                    }
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis"
                }
            }
        }
    }
});

export const ccHeaderStyle = {
    backgroundColor: "#EBF0F4",
    fontWeight: "bolder",
    fontSize: "20",
    height: "60",
    width: "10",
    position: "sticky",
    zIndex: 8
};

export const siemensColorStyle = {
    color: "#016684"
};

export const ccTableIcons = {
    Clear: forwardRef((props, ref) => (
        <MdClear {...props} ref={ref} style={siemensColorStyle}/>
    )),
    DetailPanel: forwardRef((props, ref) => (
        <MdChevronRight {...props} ref={ref} style={siemensColorStyle}/>
    )),
    Filter: forwardRef((props, _ref) => <p {...props} />),
    FirstPage: forwardRef((props, ref) => (
        <MdFirstPage {...props} ref={ref} style={siemensColorStyle}/>
    )),
    LastPage: forwardRef((props, ref) => (
        <MdLastPage {...props} ref={ref} style={siemensColorStyle}/>
    )),
    NextPage: forwardRef((props, ref) => (
        <MdChevronRight {...props} ref={ref} style={siemensColorStyle}/>
    )),
    PreviousPage: forwardRef((props, ref) => (
        <MdChevronLeft {...props} ref={ref} style={siemensColorStyle}/>
    )),
    ResetSearch: forwardRef((props, ref) => (
        <MdClear {...props} ref={ref} style={siemensColorStyle}/>
    )),
    Search: forwardRef((props, ref) => (
        <MdSearch {...props} ref={ref} style={siemensColorStyle}/>
    )),
    SortArrow: forwardRef((props, ref) => <MdArrowUpward {...props} ref={ref}/>),
    Attach: forwardRef((props, ref) => <MdOutlineAttachFile {...props} ref={ref}/>),
    Add: forwardRef((props, ref) => <MdAddBox {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <MdCheck {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <MdDeleteOutline {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <MdEdit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <MdSaveAlt {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <MdRemove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <MdViewColumn {...props} ref={ref}/>),
    Resize: forwardRef((props, ref) => (
        <MdSwapHoriz {...props} ref={ref} color="secondary" style={iconStyle}/>
    ))
};

export const iconStyle = {
    flex: "1",
    right: "-8px",
    width: "16px",
    cursor: "col-resize",
    height: "100%",
    zIndex: "20",
    position: "absolute",
    color: "inherit",
    display: "flex",
    justifyContent: "center"
}

export const creditButtonStyle = {
    height: "100%",
    width: "100%",
    backgroundColor: "#003446",
    borderWidth: "1",
    borderRadius: "3",
    borderColor: "#003446",
    textTransform: "none"
};

export const submitButtonStyle = {
    height: "50px",
    width: "120px",
    backgroundColor: "#016684",
    position: 'relative',
    marginTop: "10px",
    textTransform: "none"
};
