import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import styled from 'styled-components';
import {PartPanel} from "./PartPanel";
import {LeadsPanel} from "./LeadsPanel";
import {PackageBodyPanel} from "./PackageBodyPanel";
import {SupplyChainPanel} from "./SupplyChainPanel";
import {FindChipsPanel} from "./FindChipsPanel";

const STabs = styled(Tabs)`
  font-family: BlinkMacSystemFont, "Segoe UI", sans-serif;
  font-size: 12px;
  width: 100%;
  height: 100%;
  background: white;
`;

const STabList = styled(TabList)`
  list-style-type: none;
  padding: 4px;
  display: flex;
  margin: 0;
`;
STabList.tabsRole = 'TabList';

const STab = styled(Tab)`
  margin-right: 4px;
  border: 1px solid lightGray;
  padding: 4px;
  user-select: none;
  cursor: arrow;
  &.is-selected {
    border-bottom: 1px solid white;
  }
  &:focus {
    outline: none;
  }
`;
STab.tabsRole = 'Tab';
const STabPanel = styled(TabPanel)`
  display: none;
  border: 1px solid lightGray;
  padding: 10px;
  margin-top: -5px;
  width: 100%;
  height: 100%;
  background: white;
  &.is-selected {
    display: block;
  }
`;
STabPanel.tabsRole = 'TabPanel';

export const PackageTabs = (pkg) => {

    if (pkg && pkg.pkg && pkg.pkg.groups) {
        return (
            <STabs
                selectedTabClassName='is-selected'
                selectedTabPanelClassName='is-selected'
            >
                <STabList>
                    <STab>Part</STab>
                    <STab>Body</STab>
                    <STab>Leads</STab>
                    <STab>FindChips</STab>
                    <STab>3D</STab>
                </STabList>
                <STabPanel>
                    <PartPanel part={pkg.pkg.part} />
                </STabPanel>
                <STabPanel>
                    <PackageBodyPanel body={pkg.pkg.body}/>
                </STabPanel>
                <STabPanel>
                    <LeadsPanel groups={pkg?.pkg?.groups}/>
                </STabPanel>
                <STabPanel>
                    <SupplyChainPanel part={pkg.pkg.part}/>
                </STabPanel>
                <STabPanel>
                    <FindChipsPanel part={pkg.pkg.part}/>
                </STabPanel>
            </STabs>
        );
    } else {
        return <div/>;
    }
}
