import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import React from 'react';
import Layout from './components/Layout.js';
import {ContentCreationRequests} from "./components/cc/CCRequests";
import {CCTable} from "./components/cc/CCTable";
import {Login} from "./pages/Login";
import ILogout from "./components/ILogout";
import Profile from "./components/Profile";
import {authenticationService} from "./services/auth.service";
import useAuth from "./services/useAuth";
import {CCPurchaseOrders} from "./components/cc/CCPurchaseOrders";


function App() {
    const user = useAuth();
    if (!authenticationService.isAuthenticated()) {
        localStorage.setItem("requested_location", window.location.href);
        return <Login/>;
    } else {
        const url = localStorage.getItem("requested_location");
        if (url) {
            localStorage.removeItem("requested_location");
            document.location = url;
        }
    }
    return (
        <BrowserRouter style={{backgroundColor: '#016684'}}>
            <Layout user={user}>
                <Routes>
                    <Route exact path='/' element={<ContentCreationRequests/>}/>
                    <Route exact path='/cc' element={<ContentCreationRequests/>}/>
                    <Route exact path='/cc/orders' element={<CCPurchaseOrders/>}/>
                    <Route path='/cc/:ccId' element={<CCTable/>}/>
                    <Route path='/profile' element={<Profile user={user}/>}/>
                    <Route path='/ilogout' element={<ILogout/>}/>
                    <Route path='/logout' element={<Login/>}/>
                    <Route path='/logoutunknownUser' element={<Login/>}/>
                    <Route path='/out' element={<Login/>}/>
                </Routes>
            </Layout>
        </BrowserRouter>
    );
}

export default App;
