import React, {useState} from "react";
import {DialogContent, IconButton, Menu} from "@mui/material";
import {MdOutlineRemoveCircleOutline} from "react-icons/md";
import {VPLTooltip} from "../VPLTooltip";
import {ContentCreationClient} from "../../services/ContentCreationClient";
import {VPLDialogActions} from "../VPLDialogActions";
import {ProgressBar} from "../ProgressBar";
import {Error} from "../Error";
import {useNavigate} from "react-router-dom";

export const DeleteRequest = ({ccId, request}) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const open = Boolean(anchorEl);
    const anchorOrig = {
        vertical: "top",
        horizontal: "left"
    };

    function handleClose() {
        setAnchorEl(null);
    }

    function closeErrorMessage() {
        setError(false);
        setErrorMessage(null);
    }

    function handleSubmission() {
        const ccClient = new ContentCreationClient();
        setLoading(true);
        ccClient.deleteRequest(ccId,
            () => {
                setLoading(false);
                handleClose();
                navigate(`/cc`);
            },
            (newError) => {
                setLoading(false);
                setError(true);
                setErrorMessage(newError);
            });
    }

    return (
        <React.Fragment>
            <VPLTooltip placement="bottom-start" title="Remove request">
                <IconButton
                    style={{padding: 0, margin: 5}}
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget)
                    }}
                    size="large">
                    {request && <MdOutlineRemoveCircleOutline style={{color: "black"}}/>}
                </IconButton>
            </VPLTooltip>
            <Menu
                id="simple-menu"
                keepMounted
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={anchorOrig}
                transformOrigin={anchorOrig}>

                {loading && <ProgressBar/>}

                <Error error={error} errorMessage={errorMessage} closeErrorMessage={closeErrorMessage}/>

                <DialogContent>
                    <h4>Delete request: {request?.name}</h4>
                    <div>Are you sure?</div>
                </DialogContent>
                <VPLDialogActions handleClose={handleClose} handleSubmit={handleSubmission}/>
            </Menu>
        </React.Fragment>
    );
}