import React from 'react';
import Header from './Header';
import Navigation from './Navigation';
import styled from 'styled-components';
import {Alert} from "./Alert";
import useMessages from "./useMessages";

const Wrapper = styled.div`
    @media (min-width: 700px) {
        display: flex;
        top: 64px;
        bottom: 0px;
        position: relative;
        height: calc(100% - 50px);
        width: calc(100% - 120px);
        flex: auto;
        flex-direction: column;
    }
`;
const Main = styled.main`
    background-color: white;
    position: fixed;
    height: calc(100% - 85px);
    width: calc(100% - 100px);
    margin-left: 60px;
    margin-right: 60px;
    margin-bottom: 0px;
    border-radius: 1;
    padding: 1em;
    overflow-y: auto;
`;

const Layout = ({ user, children }) => {
    const messages = useMessages();

    return (
        <div style={{backgroundColor: "#016684", overflow: "scroll"}}>
            <Header />
            <Wrapper>
                <Navigation user={user}/>
                <Main>
                    {messages.request.map((o) => {
                        return <Alert message={o.text} />;
                    })}
                    {children}
                </Main>
            </Wrapper>
        </div>
    );
}

export default Layout;