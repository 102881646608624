import Grid from "@mui/material/Grid";
import {UploadBomDialog} from "./UploadBomDialog";
import {TablePagination} from "@mui/material";
import Credits from "./Credits";

export const ContentCreationRequestsHeader = (props) => {

    return (
        <div style={{ boxShadow: "none", border: "1px solid #006486", padding: 10 }}>
            <span style={{fontWeight: 'normal', fontSize: 24, padding: 10, margin: 10}}>Content creation requests</span>
            <Grid container spacing={3} direction="row" alignItems="flex-end" justifyContent="flex-end" style={{marginTop: 10}}>
                <Grid item xs={1}>
                    <Credits/>
                </Grid>
                <Grid item xs={1}>
                    <UploadBomDialog/>
                </Grid>
                <Grid item xs={6}/>
                <Grid item xs={4}>
                    <TablePagination
                        {...props}
                        rowsPerPageOptions={[10, 50, 100, { value: props.rows || 0, label: "All" }]}
                    />
                </Grid>
            </Grid>
        </div>
    );

}
