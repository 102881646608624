import React, {useEffect, useState} from "react";
import {ContentCreationClient} from "../../services/ContentCreationClient";
import {useParams} from "react-router-dom";

const useOrders = () => {
    const {ccId} = useParams();
    const [request, setRequest] = useState({request: [], errorMessage: null, loading: true});

    useEffect(() => {
        let abortController = new AbortController();
        const ccClient = new ContentCreationClient();
        ccClient.getPurchaseOrders().then(res => {
            for(let po of res?.request) {
                po.purchaseOrderDate = new Date(po.purchaseOrderDate*1000);
                po.expirationDate = new Date(po.expirationDate*1000);
            }

            console.log(res);
            res.loading = false;
            setRequest(res)
        });
        return () => {
            abortController.abort();
        }
    }, [ccId])

    return request;
};

export default useOrders;