export const PackageBodyPanel = ({body}) => {
    return (
        <div>
            <p>Type: {body.type}</p>
            <p>Size: {body.size}</p>
            <p>Direction: {body.direction}</p>
            <p>Mount: {body.mount}</p>
        </div>
    );
}
