import React from "react";
import {Grid} from "@mui/material";
import {PartNotes} from "./PartNotes";
import {PartDatasheetLink} from "./PartDatasheetLink";
import {PartAttachment} from "./PartAttachment";

export const PartButtons = ({requestId, part, notify}) => {

    return (
        <React.Fragment>
            <Grid container direction={"row"} >
                <Grid item >
                    <PartDatasheetLink part={part} notify={notify}/>
                </Grid>
                <Grid item >
                    <PartNotes part={part} notify={notify}/>
                </Grid>
                <Grid item >
                    <PartAttachment requestId={requestId} part={part} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}