import React, {useEffect, useState} from "react";
import {ContentCreationClient} from "../services/ContentCreationClient";

const useMessages = () => {
    const [request, setRequest] = useState({request: [], errorMessage: null, loading: true});

    useEffect(() => {
        let abortController = new AbortController();
        const ccClient = new ContentCreationClient();
        ccClient.getMessages().then(res => {
            res.loading = false;
            setRequest(res)
        });
        return () => {
            abortController.abort();
        }
    }, [])

    return request;
};

export default useMessages;