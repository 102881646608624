import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {MdOutlineCreate, MdOutlineExitToApp} from "react-icons/md";
import {VPLTooltip} from "./VPLTooltip";
import {Avatar, Box, Button} from "@mui/material";
import Grid from "@mui/material/Grid";

const Nav = styled.nav`
    position: fixed;
    overflow-y: none;
    width: 60px;
    height: calc(100%);
    background-color: #016684;
`;

const Navigation = ({user}) => {
    const [username, setUsername] =useState(null);

    useEffect(()=> {
        setUsername(stringAvatar(user))
    }, [user]);

    function stringAvatar(userInfo) {
        let name = "? ";
        if (userInfo) {
            if (userInfo.firstName || userInfo.lastName) {
                name = (userInfo.firstName ? userInfo.firstName : " ") + " " + (userInfo.lastName ? userInfo.lastName : " ");
                name = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
            } else if (userInfo.email) {
                name = `${userInfo.email[0]}${userInfo.email[1]}`;
            }
        }
        return name;
    }

    return (
        <Nav>
            <Grid container direction={"column"} style={{height: "100%"}}>
                <Grid item>
                    <Box display="flex" flexDirection="column" justifyContent="flex-start">
                        <Button style={{position: 'absolute', top: 40}}>
                            <VPLTooltip placement="bottom-start" title="Content creation">
                                <Link to="/cc"><MdOutlineCreate size={32} color={"white"} /></Link>
                            </VPLTooltip>
                        </Button>
                    </Box>
                </Grid>
                <Grid item>
                    <Box display="flex" flexDirection="column" justifyContent="flex-start">
                        <Button style={{position: 'absolute', bottom: 140}}>
                            <VPLTooltip placement="bottom-start" title="Profile">
                            <Link to="/profile" style={{ textDecoration: 'none' }}>
                                <Avatar children={username} />
                            </Link>
                            </VPLTooltip>
                        </Button>
                    </Box>
                </Grid>
                <Grid item>
                    <Box display="flex" flexDirection="column" justifyContent="flex-start">
                        <Button style={{position: 'absolute', bottom: 80}}>
                            <VPLTooltip placement="right-start" title="Sign out">
                                <Link to="/ilogout"><MdOutlineExitToApp size={32} color={"white"} /></Link>
                            </VPLTooltip>
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Nav>
    );
};
export default Navigation;