import React, {useState} from "react";
import {Dialog, DialogContent, Paper} from "@mui/material";
import {ColorButton, creditButtonStyle, WhiteTextTypography} from "./ContentCreationStyles";
import {MdAdd} from "react-icons/md";
import FileUpload from "./FileUpload";
import {ContentCreationClient} from "../../services/ContentCreationClient";
import {VPLDialogActions} from "../VPLDialogActions";
import {VPLDialogTitle} from "../VPLDialogTitle";
import Draggable from "react-draggable";
import {ProgressBar} from "../ProgressBar";
import {useNavigate} from "react-router-dom";
import {Error} from "../Error";

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}


export const UploadBomDialog = () => {
    const [open, setOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const navigate = useNavigate();

    function closeErrorMessage() {
        setErrorMessage(null);
    }

    function handleClickToOpen() {
        setOpen(true);
    }

    function handleToClose() {
        setOpen(false);
    }

    function setFile(files) {
        setSelectedFile(files.length > 0 ? files[0] : null);
    }

    function handleSubmission() {
        setLoading(true);
        const ccClient = new ContentCreationClient();
        ccClient.addRequest(selectedFile, (id) => {
                navigate(`/cc/${id}`)
            },
            (error) => console.log(error));
    }

    return (
        <div>
            <ColorButton variant="outlined" onClick={handleClickToOpen} style={creditButtonStyle}>
                <div>
                    <WhiteTextTypography variant="h5">New</WhiteTextTypography>
                    <MdAdd/>
                </div>
            </ColorButton>
            <Dialog open={open} onClose={handleToClose}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title">
                <VPLDialogTitle handleClose={handleToClose} title={"Upload content creation request"}/>
                <DialogContent>
                    <a href={"./VPL Content Creation Request template.xlsx"}
                       download="VPL Content Creation Request template.xlsx">You can use this file as a template</a>
                    <Error
                        error={errorMessage}
                        errorMessage={errorMessage}
                        closeErrorMessage={closeErrorMessage}
                    />
                    {loading && <ProgressBar size={10}/>}
                    <FileUpload multiple={false} updateFilesCb={setFile} callUpdateFilesCb={setFile}
                                dragLabel={"Drag and drop your BOM"}/>
                </DialogContent>
                <VPLDialogActions handleClose={handleToClose} handleSubmit={handleSubmission}/>
            </Dialog>
        </div>
    );
}