import { Fragment } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";

export const Error = ({error, errorMessage, closeErrorMessage}) => {
    return (
        <Snackbar
            style={{ height: "100%" }}
            anchorOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
            open={error}
            autoHideDuration={6000}
            onClose={closeErrorMessage}
            message={errorMessage}
            action={
                <Fragment>
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={closeErrorMessage}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Fragment>
            }
        />
    );
}
