import MaterialTable from "@material-table/core";
import React from "react";
import {Paper} from "@mui/material";
import {StyledEngineProvider, ThemeProvider} from "@mui/material/styles";
import {ccTableIcons, ccTableTheme} from "./ContentCreationStyles";
import {Error} from "../Error";
import {ContentCreationRequestsHeader} from "./ContentCreationRequestsHeader";
import {useNavigate} from "react-router-dom";
import useRequests from "./useRequests";


export const ContentCreationRequests = () => {
    const navigate = useNavigate();
    let requests = useRequests();

    const columns =
        [
            {title: "ID", field: "id", cellStyle: {textAlign: "left"}},
            {title: "Name", field: "name", cellStyle: {textAlign: "left"}},
            {title: "# of parts", field: "numberOfParts", cellStyle: {textAlign: "left"}},
            {title: "Service", field: "service", cellStyle: {textAlign: "left"}},
            {title: "Requested on", field: "date", cellStyle: {textAlign: "left"}, defaultSort: "desc"},
            {title: "Status", field: "status", cellStyle: {textAlign: "left"}},
            {
                title: "Expected Release",
                field: "expectedReleaseDate",
                cellStyle: {textAlign: "left"},
                render: (row) => convertMillToDate(row.expectedReleaseDate)
            }
        ];

    function convertMillToDate(milliSecond) {
        if (milliSecond > 0) {
            let dateVal = new Date(milliSecond).getDate();
            let monthFromDate = new Date(milliSecond).getMonth() + 1;
            let yearFromDate = new Date(milliSecond).getFullYear();
            return monthFromDate + '-' + dateVal + '-' + yearFromDate;
        }
        return "";
    }

    function handleRowClick(_event, rowData) {
        navigate(`/cc/${rowData.id}`);
    }

    function closeErrorMessage() {
        if (requests) {
            requests.errorMessage = null;
        }
    }

    return (
        <React.Fragment>
            <Error
                error={requests?.errorMessage}
                errorMessage={requests?.errorMessage}
                closeErrorMessage={closeErrorMessage}
            />
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={ccTableTheme}>
                    <MaterialTable
                        columns={columns}
                        data={requests?.requests}
                        icons={ccTableIcons}
                        isLoading={requests?.loading}
                        options={{
                            headerStyle: {
                                backgroundColor: "#EBF0F4",
                                fontWeight: "bolder",
                                fontSize: 14,
                                position: "sticky"
                            },
                            pageSize: 100,
                            toolbar: false,
                            defaultExpanded: false,
                            filtering: false,
                            exportAllData: true,
                            showTitle: true,
                            rowStyle: () => {
                                return {wordWrap: "break-word", borderBottom: "none", fontSize: 14};
                            },
                            padding: "normal",
                            paginationPosition: "top",
                            tableLayout: "fixed",
                            columnResizable: true,
                            draggable: true
                        }}
                        components={{
                            Pagination: (props) => (
                                <ContentCreationRequestsHeader rows={requests?.requests?.length} {...props}/>
                            ),
                            Container: (props) => <Paper {...props} elevation={0}/>
                        }}
                        onRowClick={handleRowClick}
                    />
                </ThemeProvider>
            </StyledEngineProvider>
        </React.Fragment>
    );
}